import { USER_DESIGN_GROUPS } from '@/conf/userDesignGroups.js';

let timerInterval = null;

const ENV_CONFIG = {
  production: {
    switchTimeMinutes: 10
  },
  staging: {
    switchTimeMinutes: 5
  }
};

const getCurrentEnv = () => {
  const isStaging = window.location.hostname.includes('staging') ||
        window.location.hostname.includes('localhost');
  return isStaging ? 'staging' : 'production';
};

const getEnvConfig = () => {
  const env = getCurrentEnv();
  return ENV_CONFIG[env];
};


export const isPaidTraffic = () => {
  const url = window.location.href;
  return url.includes('utm_') || url.includes('gclid') || url.includes('fbclid');
};

export const getDefaultValue = () => {
  return localStorage.getItem('default');
};

export const isDesignWhite = () => {
  const defaultValue = getDefaultValue();
  return defaultValue === USER_DESIGN_GROUPS.NOT_PAID_TRAFFIC ||
        defaultValue === USER_DESIGN_GROUPS.PAID_TRAFFIC_AFTER_30_MIN;
};

export const initializeTrafficSource = () => {
  const defaultValue = getDefaultValue();

  if (defaultValue === USER_DESIGN_GROUPS.PAID_TRAFFIC_AFTER_30_MIN) {
    return;
  }

  if (!isPaidTraffic() && !defaultValue) {
    localStorage.setItem('default', USER_DESIGN_GROUPS.NOT_PAID_TRAFFIC);
    return;
  }

  if (isPaidTraffic() && (!defaultValue || defaultValue === USER_DESIGN_GROUPS.PAID_TRAFFIC)) {
    localStorage.setItem('default', USER_DESIGN_GROUPS.PAID_TRAFFIC);

    const switchTime = localStorage.getItem('designSwitchTime');
    if (!switchTime) {
      localStorage.setItem('designSwitchTime', new Date().getTime());
    }

    startGlobalTimer();
  }
};

export const checkDesignSwitchTimer = () => {
  const defaultValue = getDefaultValue();

  if (defaultValue !== USER_DESIGN_GROUPS.PAID_TRAFFIC) {
    stopGlobalTimer();
    return;
  }

  const switchTimeStr = localStorage.getItem('designSwitchTime');
  if (!switchTimeStr) {
    return;
  }

  const switchTime = parseInt(switchTimeStr, 10);
  const currentTime = new Date().getTime();
  const elapsedMinutes = (currentTime - switchTime) / (1000 * 60);
  const config = getEnvConfig();

  if (elapsedMinutes >= config.switchTimeMinutes) {
    localStorage.setItem('default', USER_DESIGN_GROUPS.PAID_TRAFFIC_AFTER_30_MIN);
    stopGlobalTimer();
    window.location.reload();
  }
};

export const startGlobalTimer = () => {
  stopGlobalTimer();

  timerInterval = setInterval(() => {
    checkDesignSwitchTimer();
  }, 60000);
};

export const stopGlobalTimer = () => {
  if (timerInterval) {
    clearInterval(timerInterval);
    timerInterval = null;
  }
};
